<template>
  <div class="overall-page">
    <div class="head">
      <div class="head-left">
        <img src="@/assets/img/wang/back.png" @click="back" alt="" />
        <span>信息详情</span>
      </div>
      <img v-if="detailData.isWarning == 0 && !isWangxinban" class="img" @click="isWariingFn"
        src="@/assets/img/gjh/isWariing.png" alt="" srcset="" />
    </div>
    <div class="page" v-loading="ifWarning">
      <Breadcrumb :routerList="routerList"></Breadcrumb>
      <div class="info-box">
        <div class="head-card">
          <div class="head-card-left">
            <img class="shadow" src="@/assets/img/wang/shadow.png" />
          </div>
          <div class="head-card-right">
            <div class="title-box">
              <div class="title-box-left" v-if="detailData.isWarning == 1">
                <div>已预警</div>
              </div>
              <div class="title-box-right" v-html="detailData.title"></div>
            </div>
            <div class="planName-box">
              <el-tooltip class="item" effect="dark" v-for="(planName, inx) in detailData.dataWordVosOne" :key="inx"
                :content="planName" :disabled="planName.length < 6" placement="top">
                <div class="warning-type cupo m-r-32 overflowText">
                  {{ planName }}
                </div>
              </el-tooltip>
            </div>
            <div class="early-warning">
              <div class="platform-type">{{ detailPlatformType }}</div>
              <img class="read" src="@/assets/img/wang/yidu.png" alt="" v-if="detailData.isRead == 1" />
              <div class="text" v-if="detailData.isRead == 1" style="color: #24bb87">
                已读
              </div>
              <img class="read" src="@/assets/img/wang/weidu.png" alt="" v-if="detailData.isRead == 0" />
              <div class="text" v-if="detailData.isRead == 0" style="color: #ea3342">
                未读
              </div>
              <div class="time">发布时间：{{ detailPublishTime }}</div>
            </div>
            <div class="operation">
              <div class="copy" @click="$main.copyFun(detailData.copyAll)">
                <img src="@/assets/img/wang/copy-all.png" />
                <span>复制全部</span>
              </div>
              <div class="copy" @click="
                $main.copyFun(detailData.copyUrlAndTitle, '复制链接和标题')
                ">
                <img src="@/assets/img/wang/copy-link.png" />
                <span>复制链接和标题</span>
              </div>
              <div class="copy" @click="createImgTask" :class="{ disabled: imgUrl }">
                <img src="@/assets/img/wang/jietu.png" />
                <span>页面截图</span>
              </div>
              <div class="copy" @click="dataCollection(detailData)">
                <div class="collectionDiv" v-if="detailData.isCollect == 0">
                  <img src="@/assets/img/wang/collection.png" />
                  <span style="font-size: 0.875rem">收藏</span>
                </div>
                <div class="collectionDiv" v-else>
                  <img src="@/assets/img/zhang/yishoucang.png" alt="" />
                  <div style="color: #ff9000; font-size: 0.875rem">已收藏</div>
                </div>
              </div>
              <div class="copy" @click="openError({ id: detailId })">
                <img src="@/assets/img/wang/del.png" />
                <span>删除</span>
              </div>
            </div>
          </div>
          <img class="shadow2" src="@/assets/img/wang/shadow2.png" />
          <img class="disposed" src="@/assets/img/wang/disposed.png" v-if="detailData.isDisposal == 1" />
        </div>
        <div class="platform">
          <div class="platform-list">
            <div class="platform-time">
              <span style="
                  max-width: 80%;
                  overflow: hidden;
                  word-break: keep-all;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                ">
                原文链接：
                <a :href="detailData.sourceUrl" target="_blank" style="color: #2878ff">
                  {{ detailData.sourceUrl }}
                </a>
              </span>
              <img @click="$main.copyFun(detailData.sourceUrl)" src="@/assets/img/wang/copy-all.png" />
            </div>
            <div class="platform-time">
              <span>发布时间：{{ detailPublishTime }}</span>
              <img @click="$main.copyFun(detailPublishTime)" src="@/assets/img/wang/copy-all.png" />
            </div>
          </div>
          <div class="platform-list">
            <div class="platform-time" v-if="detailData.platformType == 1">
              <span>网站名称：{{ detailData.source }}</span>
              <img @click="$main.copyFun(detailData.source)" src="@/assets/img/wang/copy-all.png"
                v-if="detailData.source" />
            </div>
            <div class="platform-time" v-else>
              <span>作者名称：{{ detailData.author }}</span>
              <img @click="$main.copyFun(detailData.author)" src="@/assets/img/wang/copy-all.png" />
            </div>
            <div class="platform-time" v-if="detailData.isWarning == 1 && detailData.warningTime">
              <span>预警时间：{{ detailData.warningTime }}</span>
              <img @click="$main.copyFun(detailData.warningTime)" src="@/assets/img/wang/copy-all.png" />
            </div>
            <!-- <div class="platform-time" v-if="detailData.createTime">
              <span>预警时间：{{ detailData.createTime }}</span>
              <img @click="$main.copyFun(detailData.createTime)" src="@/assets/img/wang/copy-all.png" />
            </div> -->
          </div>
          <div class="platform-list">
            <div class="platform-time">
              <span>所属平台：{{ detailPlatformType }}</span>
              <img @click="$main.copyFun(detailPlatformType)" src="@/assets/img/wang/copy-all.png" />
            </div>
            <div class="platform-time" v-if="detailData.createTime">
              <span>入库时间：{{ detailData.createTime }}</span>
              <img @click="$main.copyFun(detailData.createTime)" src="@/assets/img/wang/copy-all.png" />
            </div>
          </div>
          <!-- <div class="platform-name">
            <span>所属平台：{{ detailPlatformType }}</span>
            <img @click="$main.copyFun(detailPlatformType)" src="@/assets/img/wang/copy-all.png" />
          </div> -->
          <div class="img" v-loading="loadingImg" element-loading-text="正在截图中">
            <el-image v-if="imgUrl" :src="imgUrl" class="img-c" :preview-src-list="[imgUrl]">
            </el-image>
            <img v-else class="img-c" src="../../assets/img/gjh/head-r.png" alt="" srcset="" />
          </div>
        </div>
      </div>
      <!-- 错误表述 -->
      <div class="be-similar">
        <div class="be-similar-account">
          <span>错误表述</span><span style="margin-left: 0.625rem">({{ detailData.dataWordVos.length }}条)</span>
          <div class="be-similar-color"></div>
          <el-select class="select-box" v-model="selectValue" placeholder="请选择" @change="handleCommand">
            <el-option v-for="item in selectOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="flex" style="flex-wrap: wrap;margin-top:0.9375rem">
          <div class="data-box-right biaoji-box box-item" v-for="(item, index) in detailData.dataWordVos" :key="index">
            <div class="data-info">
              <div class="subject-type">
                <span style="color: #ea3342"> 错误表述：</span>
                <span> {{ item.matchKeyword || "无" }}</span>
              </div>
              <div class="region">
                <span style="color: #00c1ad">建议更正：</span>
                <span> {{ item.correctKeyword || "无" }}</span>
              </div>
              <!-- <div class="region">
                <span style="color:#00C1AD">状态：</span> <span> {{item.disposalStatusName || "无" }}</span>
              </div> -->
            </div>
            <div class="biaoji" :style="{ 'background-color': $main.getBiaojiColor_fn(item.disposalStatusName) }">
              {{ item.disposalStatusName }}
            </div>
          </div>
        </div>
      </div>
      <div class="cut">
        <div class="cut-left">
          <div class="cut-tag" @click="cut(index)" v-for="(item, index) in list" :key="index"
            :style="index != idx ? 'border:0rem;color:#333333' : ''">
            {{ item }}
          </div>
        </div>
        <div class="cut-right">
          <div v-if="idx == 1" class="notice" @click="openSetNoticeTextDialog">
            生成通知函
          </div>
          <div v-if="idx == 1" class="success" @click="openDisposalRecordDialog">
            处置已完成
          </div>
        </div>
      </div>
      <div v-if="idx == 0" class="content font16" v-html="detailData.content"></div>

      <!-- 处置记录 -->
      <div class="disposal-records" v-if="idx == 1" v-loading="disposalLoading">
        <div class="disposal-records-list" v-for="(item, index) in disposalRecordList" :key="index">
          <div class="records-head">
            <div class="head-left">处置记录{{ index + 1 }}</div>
            <div class="head-right">
              <!-- <span>处置人员：{{ item.updateUser }}</span>
              <span>处置时间：{{ item.updateTime }}</span> -->
            </div>
          </div>
          <div class="chuzhi">
            <span>处置人员：{{ item.updateUser }}</span>
            <span>处置时间：{{ item.updateTime }}</span>
            <span>处置方式：{{ item.disposalType }}</span>
          </div>
          <div class="remake" v-html="item.disposalContent
            ? '处置内容：' + item.disposalContent
            : '处置内容：无'
            "></div>
          <div class="enclosure">
            <div class="enclosure-left">上传附件</div>
            <div class="enclosure-right">
              <div class="file-box" v-for="(items, indexs) in item.enclosures" :key="indexs"
                @click="downloadFile(items.url)">
                <img class="excel" src="@/assets/img/wang/excel.png" />
                <div class="fill-mame">
                  <span>{{ items.name }}</span>
                  <span>({{ items.size || "99.9KB" }})</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Bitmap v-if="disposalRecordList.length == 0" />
      </div>
    </div>
    <!-- 新增处置记录弹窗 -->
    <el-dialog :visible.sync="disposalRecordDialog" :close-on-click-modal="false" :close-on-press-escape="false"
      width="40vw">
      <div slot="title" class="dialog-header">
        <span class="el-dialog__title">新增处置记录</span>
      </div>
      <div class="dialog-body">
        <el-row>
          <el-col :span="11">
            <div class="dialog-body-desc">
              <span class="symbol">*</span>
              处置人员
            </div>
            <div class="m-t">
              <el-input v-model="addDisposalRecord.disposalName" placeholder="请输入内容" style="width: 100%"></el-input>
            </div>
          </el-col>
          <el-col :span="11" style="margin-left: 2.5rem">
            <div>
              <div class="dialog-body-desc">
                <span class="symbol">*</span>
                处置时间
              </div>
              <div class="m-t">
                <el-date-picker v-model="addDisposalRecord.disposalTime" type="datetime" placeholder="选择日期时间"
                  style="width: 100%"></el-date-picker>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 1.5rem">
          <el-col>
            <div class="dialog-body-desc">处置内容</div>
            <div class="m-t">
              <el-input type="textarea" :rows="6" placeholder="请输入内容"
                v-model="addDisposalRecord.disposalContent"></el-input>
            </div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 1.5rem">
          <el-col>
            <div class="dialog-body-desc">上传附件</div>
            <el-upload class="avatar-uploader" :action="baseURL + actionUrl" :file-list="fileList"
              :headers="{ Authorization: token }" :on-success="handleAvatarSuccess" :on-error="handleAvatarError"
              :before-upload="beforeAvatarUpload" :on-remove="handleChange" :multiple="true">
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="disposalRecordDialog = false">取 消</el-button>
        <el-button type="primary" @click="saveDisposalRecord">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 删除二次确认框 -->
    <deleteDialog ref="openDialog" @deleteFn="dataDelete"></deleteDialog>
    <!-- 生成通知函弹窗 -->
    <div class="setNoticeTextBox">
      <el-dialog :visible.sync="setNoticeTextDialog" :close-on-click-modal="false" :close-on-press-escape="false"
        width="40vw">
        <div slot="title" class="dialog-header">
          <span class="el-dialog__title">生成通知函</span>
        </div>
        <div class="dialog-body">
          <el-row>
            <el-col>
              <div class="dialog-body-desc">
                <span class="symbol">*</span>
                标题
              </div>
              <div class="m-t">
                <el-input type="text" :rows="6" placeholder="请输入内容" v-model="noticeTextData.title" clearable></el-input>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 1.5rem">
            <el-col :span="11">
              <div class="dialog-body-desc">
                <span class="symbol">*</span>
                发函机构
              </div>
              <div class="m-t">
                <el-input placeholder="请输入内容" style="width: 100%" v-model="noticeTextData.orgName" clearable></el-input>
              </div>
            </el-col>
            <el-col :span="11" style="margin-left: 2.5rem">
              <div>
                <div class="dialog-body-desc">
                  <span class="symbol">*</span>
                  发函时间
                </div>
                <div class="m-t">
                  <el-date-picker type="datetime" placeholder="选择日期时间" style="width: 100%"
                    v-model="noticeTextData.orgTime" value-format="yyyy-MM-dd HH:mm:ss" clearable></el-date-picker>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 1.5rem">
            <el-col :span="11">
              <div class="dialog-body-desc">
                <span class="symbol">*</span>
                主送
              </div>
              <div class="m-t">
                <el-input placeholder="请输入内容" style="width: 100%" v-model="noticeTextData.userName"
                  clearable></el-input>
              </div>
            </el-col>
            <el-col :span="11" style="margin-left: 2.5rem">
              <div>
                <div class="dialog-body-desc">
                  <span class="symbol">*</span>
                  来源
                </div>
                <div class="m-t">
                  <el-select v-model="noticeTextData.source" placeholder="请选择平台类型" style="width: 100%" multiple
                    clearable collapse-tags>
                    <el-option v-for="item in PlatformType" :key="item.value" :label="item.label"
                      :value="item.label"></el-option>
                  </el-select>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 1.5rem">
            <el-col>
              <div class="dialog-body-desc">
                <span class="symbol">*</span>
                原文链接
              </div>
              <div class="m-t">
                <el-input type="text" :rows="6" placeholder="请输入内容" v-model="noticeTextData.sourceUrl"
                  clearable></el-input>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 1.5rem">
            <el-col>
              <div class="dialog-body-desc">
                <span class="symbol">*</span>
                内容概况
              </div>
              <div class="m-t">
                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="noticeTextData.content"></el-input>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 1.5rem">
            <el-col>
              <div class="dialog-body-desc">
                <span class="symbol">*</span>
                处置建议
              </div>
              <div class="m-t">
                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="noticeTextData.propose"></el-input>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 1.5rem">
            <el-col>
              <div class="dialog-body-desc">
                <span class="symbol">*</span>
                办理情况
              </div>
              <div class="m-t">
                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="noticeTextData.message"></el-input>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 1.5rem">
            <el-col>
              <div class="dialog-body-desc">
                <span class="symbol">*</span>
                应对结果
              </div>
              <div class="m-t">
                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="noticeTextData.result"></el-input>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 1.5rem">
            <el-col :span="11">
              <div class="dialog-body-desc">
                <span class="symbol">*</span>
                联系人
              </div>
              <div class="m-t">
                <el-input placeholder="请输入内容" style="width: 100%" v-model="noticeTextData.contacts"
                  clearable></el-input>
              </div>
            </el-col>
            <el-col :span="11" style="margin-left: 2.5rem">
              <div>
                <div class="dialog-body-desc">
                  <span class="symbol">*</span>
                  联系电话
                </div>
                <div class="m-t">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="noticeTextData.phone" clearable></el-input>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="setNoticeTextDialog = false">取 消</el-button>
          <el-button type="primary" @click="submitNoticeText">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import deleteDialog from "@/components/deleteDialog.vue";
import Breadcrumb from "@/components/breadcrumb.vue";
import Error from "@/api/error/index";
import Bitmap from "@/components/bitmap.vue";
import { createImgTask } from "../../api/createImgTask/index";
export default {
  components: { Breadcrumb, deleteDialog, Bitmap },
  data() {
    const selectOptions = [
      { label: "未处置", value: 0 },
      { label: "已删除", value: 4 },
      { label: "已修改", value: 3 },
      { label: "已处置", value: 2 },
    ];
    return {
      selectOptions,
      selectValue: "",
      isWangxinban: JSON.parse(window.localStorage.getItem("isWangxinban")),
      imgUrl: "",
      loadingImg: false,
      baseURL: window.localStorage.getItem("baseURL"),
      actionUrl: window.localStorage.getItem("actionUrl"),
      ifWarning: false, // 点击人工预警触发loading
      disposalLoading: false, //处置加载效果
      token: "Bearer " + localStorage.getItem("token"),
      //上传文件列表
      fileList: [],
      // 通知函弹窗数据
      noticeTextData: {
        title: "", //标题
        orgName: "", //发函机构名称
        orgTime: null, //时间
        userName: "", //主送
        source: [], //来源
        sourceUrl: "", //原文链接
        content: "", //内容概况
        propose: "", //处置建议
        message: "", //办理情况
        result: "", //应对结果
        contacts: "", // 联系人
        phone: "", //联系人电话
      },
      // 通知函弹窗
      setNoticeTextDialog: false,
      // 新增处置记录数据
      addDisposalRecord: {
        disposalName: "",
        disposalTime: null,
        disposalContent: "",
        disposalEnclosure: "",
      },
      // 平台类型选项
      PlatformType: JSON.parse(localStorage.getItem("platform_type")),
      // 新增处置记录弹窗
      disposalRecordDialog: false,
      routerList: ["错误表述", "详情"],
      list: ["信息内容", "处置记录"],
      idx: 0,
      // 详情数据id
      detailId: null,
      // 详情数据错误类型
      planName: "",
      // 详情数据主体类型
      detailBodyType: "",
      // 详情数据平台类型
      detailPlatformType: "",
      // 详情数据发布时间
      detailPublishTime: "",
      // 详情请求内容
      detailData: {},
      //错误表述列表
      misreData: [],
      // 处置记录数据
      disposalRecordList: [],
      titleIndex: [], //标题需要标红的下标
      contentIndex: [], //内容需要标红的下标
    };
  },
  methods: {
    // 批量修改文章状态
    async handleCommand(status) {
      const Res = await Error.batchUpdateStatus({
        dataIds: [this.detailData.id],
        status,
      });
      if (Res.data.code == 200) {
        this.$message.success("修改成功");
        this.getErrorExpressionDetail();
      }
    },
    // 点击人工预警
    async isWariingFn() {
      this.ifWarning = true;
      const Res = await Error.warningPlanMisre(this.detailId);
      if (Res?.data?.code == 200) {
        this.ifWarning = false;
        this.getErrorExpressionDetail();
      } else {
        this.ifWarning = false;
      }
      // this.$main.mySetTimeOut(1000);
    },
    // 控制上传列表(删除)
    handleChange(fileList) {
      this.fileList.forEach((x, y) => {
        if (fileList.uid == x.uid) {
          this.fileList.splice(y, 1);
        }
      });
    },
    // 附件下载
    downloadFile(url) {
      let link = document.createElement("a");
      link.href = url;
      link.click();
      link.remove();
    },
    // 上传文件成功
    handleAvatarSuccess(response) {
      if (response.code == 200) {
        this.$message({
          message: "上传成功！",
          type: "success",
        });
        this.fileList.push(response.data);
      } else {
        this.$message({
          message: "上传失败" + response.msg,
          type: "error",
        });
      }
    },
    // 上传文件失败
    handleAvatarError() {
      this.$message({
        message: "上传失败",
        type: "error",
      });
    },
    //上传前文件判断
    beforeAvatarUpload(file) {
      const isTYPE =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif" ||
        file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type === "application/msword" ||
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel";
      if (!isTYPE) {
        this.$message.error("上传附件格式错误!");
      }
      return isTYPE;
    },
    // 提交通知函
    async submitNoticeText() {
      if (this.noticeTextData.title == "") {
        this.$message.warning("请输入标题！");
        return false;
      }
      if (this.noticeTextData.orgName == "") {
        this.$message.warning("请输入机构名称！");
        return false;
      }
      if (!this.noticeTextData.orgTime) {
        this.$message.warning("请选择时间！");
        return false;
      }
      if (this.noticeTextData.userName == "") {
        this.$message.warning("请输入主送！");
        return false;
      }
      if (this.noticeTextData.source.length == 0) {
        this.$message.warning("请选择来源！");
        return false;
      }
      if (this.noticeTextData.sourceUrl == "") {
        this.$message.warning("请输入原文链接！");
        return false;
      }
      if (this.noticeTextData.content == "") {
        this.$message.warning("请输入内容概况！");
        return false;
      }
      if (this.noticeTextData.propose == "") {
        this.$message.warning("请输入处置建议！");
        return false;
      }
      if (this.noticeTextData.message == "") {
        this.$message.warning("请输入办理情况！");
        return false;
      }
      if (this.noticeTextData.result == "") {
        this.$message.warning("请输入应对结果！");
        return false;
      }
      if (this.noticeTextData.contacts == "") {
        this.$message.warning("请输入联系人！");
        return false;
      }
      if (this.noticeTextData.phone == "") {
        this.$message.warning("请输入联系人电话！");
        return false;
      }
      this.noticeTextData.source = this.noticeTextData.source.join(",");
      this.noticeTextData.dataId = this.detailId;
      const res = await Error.submitNoticeText(this.noticeTextData);
      if (res.data.code == 200) {
        this.$message.success("生成通知函成功！");
        this.getDataDisposalRecord();
        this.setNoticeTextDialog = false;
      }
    },
    // 打开生成通知函弹窗
    openSetNoticeTextDialog() {
      this.noticeTextData = {
        title: this.detailData.title, //标题
        orgName: JSON.parse(localStorage.getItem("user")).orgName, //发函机构名称
        orgTime: null, //时间
        userName: JSON.parse(localStorage.getItem("user")).name, //主送
        source: [], //来源
        sourceUrl: "", //原文链接
        content: "", //内容概况
        propose: "", //处置建议
        message: "", //办理情况
        result: "", //应对结果
        contacts: "", // 联系人
        phone: "", //联系人电话
      };
      this.setNoticeTextDialog = true;
    },
    // 新增一条处置记录
    async saveDisposalRecord() {
      if (this.addDisposalRecord.disposalName == "") {
        this.$message.warning("请填写处置人员！");
        return false;
      }
      if (!this.addDisposalRecord.disposalTime) {
        this.$message.warning("请选择处置时间！");
        return false;
      }
      let data = {
        dataId: this.detailData.id,
        updateTime: this.$parent.formatDate(
          this.addDisposalRecord.disposalTime
        ),
        updateUser: this.addDisposalRecord.disposalName,
      };
      if (this.addDisposalRecord.disposalContent != "") {
        data.disposalContent = this.addDisposalRecord.disposalContent;
      }
      if (this.fileList && this.fileList.length > 0) {
        data.enclosures = JSON.stringify(this.fileList);
      }
      const res = await Error.saveDisposalRecord(data);
      if (res.data.code == 200) {
        this.disposalRecordDialog = false;
        this.getDataDisposalRecord();
        this.$message.success("新增成功！");
      }
    },
    // 打开处置记录弹窗
    openDisposalRecordDialog() {
      this.addDisposalRecord = {
        disposalName: "",
        disposalTime: null,
        disposalContent: "",
        disposalEnclosure: "",
      };
      this.fileList = [];
      this.disposalRecordDialog = true;
    },
    // 获取数据处置记录
    async getDataDisposalRecord() {
      this.disposalLoading = true;
      const res = await Error.getDataDisposalRecord(this.detailId);
      if (res.data.code == 200) {
        this.disposalRecordList = res.data.data;
        this.disposalRecordList.forEach((x) => {
          x.enclosures = JSON.parse(x.enclosures);
        });
        this.disposalRecordList = this.disposalRecordList.reverse();
        this.disposalLoading = false;
      } else {
        this.disposalRecordList = [];
        this.disposalLoading = false;
      }
    },
    // 数据删除
    async dataDelete(item) {
      const res = await Error.dataDelete(item.item.id);
      if (res.data.code == 200) {
        this.$message.success("删除成功！");
        this.$router.push("/error");
        window.close();
      }
    },
    // 打开删除弹窗
    openError(item) {
      item.message = "此条错误表述数据";
      this.$refs.openDialog.openDialog(item);
    },
    // 数据收藏或取消收藏
    async dataCollection(item) {
      if (item.isCollect == 0) {
        const res = await Error.dataCollection(this.detailId);
        if (res.data.code == 200) {
          this.$message.success("收藏成功！");
          this.getErrorExpressionDetail();
        }
      }
      if (item.isCollect == 1) {
        const res = await Error.dataNoCollection(this.detailId);
        if (res.data.code == 200) {
          this.$message.success("取消收藏成功！");
          this.getErrorExpressionDetail();
        }
      }
    },

    // 获取数据详情
    async getErrorExpressionDetail() {
      this.ifWarning = true;
      const res = await Error.getErrorExpressionDetail(this.detailId);
      if (res.data.code == 200) {
        this.detailData = res.data.data;
        this.misreData = res.data.data.misreData;
        this.imgUrl = res.data.data.websiteScreenshot;
        res.data.data?.indexVos?.forEach((v, i) => {
          if (v.titleIndex) {
            this.titleIndex.push(...JSON.parse(v.titleIndex));
          }
          if (v.contentIndex) {
            this.contentIndex.push(...JSON.parse(v.contentIndex));
          }
        });

        this.detailData.dataWordVosOne = [
          ...new Set(this.detailData.dataWordVos.map((v) => v.planName)),
        ];
        let contentIndex = this.detailData.dataWordVos
          .filter((v) => v.contentIndex)
          .reduce((pre, v) => [...pre, ...JSON.parse(v.contentIndex)], []);
        if (contentIndex.length > 0) {
          this.detailData.content = this.replaceArr(
            this.mergeIntervals(contentIndex),
            this.detailData.content,
            "content"
          );
        }
        let titleIndex = this.detailData.dataWordVos
          .filter((v) => v.titleIndex)
          .reduce((pre, v) => [...pre, ...JSON.parse(v.titleIndex)], []);
        if (titleIndex.length > 0) {
          this.detailData.title = this.replaceArr(
            this.mergeIntervals(titleIndex),
            this.detailData.title,
            "title"
          );
        }

        // this.detailData.misreData.forEach((item) => {
        //   let str = this.detailData.content
        //   let regExp = new RegExp(item.matchKeyword, "g");
        // this.detailData.content = str.replace(
        //   regExp,
        //   "<font color='red'>" + item.matchKeyword + "</font>"
        // );
        // });
        this.detailData.copyUrlAndTitle =
          "标题：" +
          this.detailData.title +
          "\n" +
          "链接：" +
          this.detailData.sourceUrl +
          "\n";
        this.detailData.copyAll =
          "标题：" +
          this.detailData.title +
          "\n" +
          "链接：" +
          this.detailData.sourceUrl +
          "\n" +
          "内容：" +
          this.detailData.content +
          "\n" +
          "错误类型：" +
          this.detailData.dataWordVosOne +
          "\n" +
          "所属平台：" +
          this.detailPlatformType +
          "\n" +
          "发布时间：" +
          this.detailPublishTime +
          "\n";
      }
      this.ifWarning = false;
    },

    // 处理数组  当二维数组的某一项的区间包含另一项的区间或者二者的区间有部分重合时,只保留大的区间
    mergeIntervals(intervals) {
      // 根据区间的起始值进行排序
      intervals.sort((a, b) => a[0] - b[0]);

      const merged = [];
      let currentInterval = intervals[0];

      for (let i = 1; i < intervals.length; i++) {
        const interval = intervals[i];

        // 如果当前区间与下一个区间有重叠或包含关系
        if (
          currentInterval[1] >= interval[0] ||
          currentInterval[1] >= interval[1]
        ) {
          // 取两个区间中较大的结束值作为新的区间的结束值
          currentInterval[1] = Math.max(currentInterval[1], interval[1]);
        } else {
          // 否则，将当前区间添加到结果中，并更新当前区间为下一个区间
          merged.push(currentInterval);
          currentInterval = interval;
        }
      }

      // 添加最后一个区间
      merged.push(currentInterval);

      return merged;
    },

    //处理标红
    replaceArr(errList, type, typeStr) {
      //数组组合之后重新排序,从末位到开头添加span标签,这样不会打乱下标位置,修改的时候依旧用正则去替换
      let sortedArr = [...errList].sort((a, b) => {
        if (a[0] === b[0]) {
          return b[1] - a[1];
        } else {
          return b[0] - a[0];
        }
      });

      sortedArr.forEach((e) => {
        let str = type;
        if (typeStr == "title")
          type =
            str.substring(0, e[0]) +
            "<span style='color: red;font-weight:800'>" +
            str.substring(e[0], e[1]) +
            "</span>" +
            str.substring(e[1]);
        if (typeStr == "content")
          type =
            str.substring(0, e[0]) +
            "<span style='color: red;font-weight:800;'>" +
            str.substring(e[0], e[1]) +
            "</span>" +
            str.substring(e[1]);
      });
      return type;
    },

    back() {
      this.$router.push({ path: "/error" });
    },
    cut(index) {
      this.idx = index;
      if (index == 1) {
        this.getDataDisposalRecord();
      }
    },
    //截图
    async createImgTask() {
      this.loadingImg = true;
      let data = {
        dataId: this.detailId,
        type: 6, //平台台账1，发布内容2，有害信息3，问题平台4，专项行动5，错误表述6，监测方案7
        url: this.detailData.sourceUrl, //信息网址
        userId: JSON.parse(window.localStorage.getItem("user")).userId,
      };
      this.initWebSocket();
      this.$message.warning("正在截图,请稍等");
      // this.websock.onmessage
      await this.$main.mySetTimeOut(2000);
      let res = await createImgTask(data);
      if (res.data.code !== 200) {
        this.$message.error("截图失败1");
      }
    },
    //-----------------------------------------------------------
    //初始化weosocket
    initWebSocket() {
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持WebSocket");
        return false;
      }
      const wsurl =
        "wss://api.sd.wangxinban.cn/websocket/" +
        window.localStorage.getItem("token"); // 正式地址
      // const wsurl = "ws://172.16.76.242:8301/websocket/"+JSON.parse(sessionStorage.getItem('userInfo')).userId   // 测试地址
      this.websock = new WebSocket(wsurl);
      this.websock.onopen = this.websocketonopen;
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    //连接成功
    websocketonopen() {
      // console.log("WebSocket连接成功");
      // let action = { message: "1" };
      // this.websocketsend(JSON.stringify(action));
    },
    //接收后端返回的数据
    websocketonmessage(e) {
      // console.log("WebSocket返回数据");
      let dataJson = JSON.parse(e.data);
      console.log(dataJson);
      this.$message.success("截图成功");
      this.imgUrl = dataJson.imagePath;
      this.loadingImg = false;
      // 在这里使用后端返回的数据，对数据进行处理渲染
    },
    //连接建立失败重连
    websocketonerror(e) {
      console.log(`连接失败的信息：`, e);
      this.initWebSocket(); // 连接失败后尝试重新连接
    },
    //关闭连接
    websocketclose(e) {
      console.log("断开连接", e);
    },
    websocketsend(Data) {
      //数据发送
      // console.log("数据发送", Data);
      this.websock.send(Data);
    },
  },
  beforeDestroy() {
    //关闭websocket连接
    this.websocketclose();
  },
  created() {
    let params = JSON.parse(decodeURIComponent(this.$route.query.info));
    console.log(params);
    if (params) {
      this.detailId = params.id;
      this.detailPlatformType = params.platformTypeName || params.platformType;
      this.detailBodyType = params.bodyType;
      this.detailPublishTime = params.publishTime;
      this.planName = params.planName;
      if (params.isChuzhi) this.cut(1);
    }
  },
  mounted() {
    this.getErrorExpressionDetail();
  },
};
</script>
<style>
::v-deep .el-loading-spinner .path {
  stroke: #ed731f;
}

::v-deep .setNoticeTextBox .el-dialog__body {
  height: 600px;
  overflow: auto;
}

::v-deep .el-upload-list__item {
  transition: none !important;
  -webkit-transition: nonne !important;
}

::v-deep .el-upload-list__item-name {
  transition: none !important;
  -webkit-transition: nonne !important;
}

::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  padding: 27px 30px;
  margin-top: 20px;
}

::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

::v-deep .el-upload--picture-card {
  width: 223px;
}

::v-deep .avatar-uploader .el-upload:hover {
  border-color: #2e59ec;
}

::v-deep .el-upload--picture-card:hover,
.el-upload:focus {
  border-color: #2e59ec;
  color: #2e59ec;
}

::v-deep .el-upload--picture-card {
  background: #fff;
}

.collectionDiv {
  display: flex;
  align-items: center;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
<style lang="scss" scoped>
//  这项目的css就是一坨屎
.data-info {
  // display: flex;
  // height: 20px;
  min-width: 270px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;

  .status {
    color: #02bc7c;
    margin-right: 46px;
  }

  .region {
    color: #02bc7c;
  }

  .subject-type {
    margin-right: 40px;
    color: #ea3342;
  }

  .company {
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 46px;
  }

  .fnas {
    margin-right: 46px;
  }
}

.planName-box {
  width: 100%;
  display: flex;
  margin-top: 10px;
}

.warning-type {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  height: 24px;
  width: auto;
  background: #ff4b1f;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-right: 16px;
}

.overall-page {
  width: 100%;

  .dialog-body-desc {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #333333;

    .symbol {
      color: red;
      margin-right: 2px;
    }
  }

  .m-t {
    margin-top: 16px;
  }

  .head {
    display: flex;
    width: 100%;
    height: 56px;
    background: #ffffff;
    position: fixed;
    margin-top: 0;
    // z-index: 100;
    position: relative;
    align-items: center;

    .head-left {
      display: flex;
      height: 100%;
      width: 50%;
      align-items: center;

      img {
        height: 38px;
        width: 38px;
        margin-left: 23px;
        margin-right: 15px;
        cursor: pointer;
      }

      span {
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
    }

    .img {
      position: absolute;
      right: 25px;
      height: 38px;
      // width: 38px;
      margin-left: 23px;
      margin-right: 15px;
      cursor: pointer;
    }
  }

  .page {
    padding-top: 72px;

    .info-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;
      background: #ffffff;
      border-radius: 8px;
      margin-top: 16px;

      .head-card {
        display: flex;
        align-items: center;
        width: 100%;
        border: 1px solid #e4e6ec;

        .head-card-left {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          height: auto;
          width: 200px;

          .shadow {
            height: 74px;
            width: 57px;
          }
        }

        .head-card-right {
          display: flex;
          flex-direction: column;
          width: calc(100% - 370px);
          margin: 40px 0px 32px 0px;
        }

        .title-box {
          display: flex;
          width: 100%;
          min-height: 42px;

          .title-box-left {
            display: flex;
            align-items: flex-start;
            height: 100%;
            width: 58px;
            margin-right: 16px;

            div {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 58px;
              height: 24px;
              background: #ffedef;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ea3342;
              white-space: nowrap;
              margin-top: 10px;
            }
          }

          .title-box-right {
            width: calc(100% - 60px);
            min-height: 42px;
            line-height: 42px;
            font-size: 30px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
          }
        }

        .early-warning {
          display: flex;
          align-items: center;
          width: 100%;
          height: auto;
          margin: 16px 0px;

          .header-img {
            height: 50px;
            width: 52px;
            border-radius: 50%;
            margin-right: 24px;
          }

          .warning-type {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 5px;
            height: 24px;
            width: auto;
            background: #ff4b1f;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-right: 16px;
          }

          .platform-type {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 5px;
            height: 24px;
            width: auto;
            background: #f58030;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-right: 16px;
          }

          .read {
            width: 17px;
            height: 15px;
            margin-right: 8px;
          }

          .text {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #02bc7c;
            margin-right: 16px;
          }

          .time {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }

        .operation {
          display: flex;
          height: 20px;
          width: auto;

          .copy {
            display: flex;
            align-items: center;
            height: 20px;
            width: auto;
            cursor: pointer;
            margin-right: 41px;

            img {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }

            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }
        }

        .shadow2 {
          height: 100%;
          width: 170px;
        }

        .disposed {
          height: 114px;
          width: 129px;
          position: absolute;
          margin-left: calc(100% - 386px);
        }
      }

      .platform {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 1164px;
        height: auto;
        background: #f4f5fa;
        padding: 24px 24px 0px 32px;
        margin: 24px 0px 0px 0px;

        .img {
          height: 110px;
          width: 110px;
          position: absolute;
          margin-left: 1000px;
          margin-bottom: 24px;

          .img-c {
            width: 100%;
            height: 100%;
          }
        }

        .platform-list {
          display: flex;
          height: 20px;
          width: 100%;
          margin-bottom: 24px;

          .platform-time {
            display: flex;
            align-items: center;
            height: 20px;
            width: 50%;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;

            img {
              height: 16px;
              width: 17px;
              margin-left: 10px;
              margin-top: 3px;
              cursor: pointer;
            }
          }
        }

        .platform-name {
          display: flex;
          align-items: center;
          height: 20px;
          width: 100%;
          margin-bottom: 24px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;

          img {
            height: 16px;
            width: 17px;
            margin-left: 10px;
            margin-top: 3px;
            cursor: pointer;
          }
        }
      }

      .content {
        width: 1164px;
        height: auto;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }

    .cut {
      display: flex;
      width: 100%;
      height: 54px;
      background: #ffffff;
      border-radius: 4px;
      margin: 16px 0px;

      .cut-left {
        display: flex;
        height: 100%;
        width: 50%;

        .cut-tag {
          display: flex;
          align-items: center;
          height: 54px;
          width: auto;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #f58030;
          border-bottom: 3px solid #f58030;
          cursor: pointer;
          margin: 0px 48px 0px 32px;
        }
      }

      .cut-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        width: 50%;

        .notice {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 108px;
          height: 32px;
          background: #f58030;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          cursor: pointer;
        }

        .success {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 108px;
          height: 32px;
          background: #dff7ef;
          border-radius: 4px;
          border: 1px solid #0fbf82;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #02bc7c;
          margin-left: 16px;
          cursor: pointer;
        }
      }
    }

    .content {
      width: 100%;
      height: auto;
      padding: 24px 240px;
      background: #ffffff;
      border-radius: 8px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      text-indent: 25px;
    }

    .be-similar {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      padding: 40px 240px 26px 240px;
      background: #ffffff;
      border-radius: 8px;
      margin-top: 16px;

      .be-similar-account {
        display: flex;
        align-items: center;
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
        margin-bottom: 10px;

        span:nth-child(1) {
          z-index: 10;
        }

        span:nth-child(2) {
          font-size: 14px;
        }

        .be-similar-color {
          width: 77px;
          height: 6px;
          background: linear-gradient(90deg, #f89d55 0%, #f4640c 100%);
          position: absolute;
          margin-left: 0px;
          margin-top: 22px;
          z-index: 9;
        }
      }

      .data-box {
        display: flex;
        width: 100%;
        height: auto;
        padding: 24px 0px;
        border-bottom: 1px solid #e4e6ec;

        .data-box-left {
          width: 104px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          img {
            height: 56px;
            width: 56px;
            border-radius: 50%;
          }

          .platform-type {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 24px;
            width: auto;
            background: #f58030;
            max-width: 104px;
            padding: 0px 22px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-top: 9px;
          }
        }

        .data-box-right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: 100%;

          .name-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: auto;
            margin-bottom: 16px;

            .name-box-left {
              display: flex;
              align-items: center;
              width: calc(100% - 170px);
              height: auto;

              span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: auto;
                height: 24px;
                padding: 0px 8px;
                background: #ff4b1f;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
                margin-right: 16px;
              }

              .name {
                height: 22px;
                width: calc(100% - 200px);
                line-height: 22px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                line-clamp: 1;
                -webkit-box-orient: vertical;
              }
            }

            .time {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              height: 100%;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }

            .bad-num {
              display: flex;
              justify-content: flex-end;
              font-size: 14px;
              width: 20%;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #e60b1e;
            }
          }

          .data-info {
            display: flex;
            height: 20px;
            width: 70%;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;

            .status {
              color: #02bc7c;
              margin-right: 46px;
            }

            .region {
              color: #02bc7c;
            }

            .subject-type {
              margin-right: 40px;
              color: #ea3342;
            }

            .company {
              max-width: 300px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-right: 46px;
            }

            .fnas {
              margin-right: 46px;
            }
          }
        }
      }
    }

    .disposal-records {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      background: #ffffff;
      border-radius: 4px;
      padding: 40px 40px 0px 40px;

      .disposal-records-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        padding-bottom: 24px;

        .records-head {
          display: flex;
          height: 56px;
          width: 100%;
          background: #f4f5fa;
          border-radius: 0px 4px 0px 0px;
          margin-bottom: 32px;

          .head-left {
            display: flex;
            align-items: center;
            width: 20%;
            height: 56px;
            padding-left: 24px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
          }

          .head-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 80%;
            height: 56px;
            padding-right: 24px;

            span {
              margin-left: 80px;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
            }
          }
        }

        .chuzhi {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 40px;
          // width: 80%;
          // height: 56px;
          padding-left: 40px;

          span {
            width: 33%;
            // margin-right: 20px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
          }
        }

        .remake {
          display: flex;
          width: 100%;
          padding: 0px 40px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-bottom: 32px;
        }

        .enclosure {
          display: flex;
          width: 100%;
          padding: 0px 40px;
          min-height: 51px;
          height: auto;

          .enclosure-left {
            display: flex;
            width: 80px;
            height: 100%;
            padding-top: 16px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }

          .enclosure-right {
            display: flex;
            flex-wrap: wrap;
            height: auto;
            width: calc(100% - 80px);

            .file-box {
              display: flex;
              align-items: center;
              height: 51px;
              min-width: 170px;
              margin: 0px 30px 16px 0px;
              background: #f5f6fa;
              border-radius: 4px;
              box-sizing: border-box;
              padding-right: 40px;
              cursor: pointer;

              .excel {
                height: 35px;
                width: 30px;
                margin: 0px 8px;
              }

              .fill-mame {
                display: flex;
                flex-direction: column;
                height: auto;
                width: auto;

                span:nth-child(1) {
                  height: 17px;
                  line-height: 17px;
                  font-size: 12px;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #333333;
                }

                span:nth-child(2) {
                  height: 17px;
                  font-size: 12px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  line-height: 17px;
                }
              }

              .fill-del {
                height: 16px;
                width: 16px;
                margin: 0px 16px 0px 14px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

.select-box {
  margin-left: 20px;
  width: 100px
}

.box-item {
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
